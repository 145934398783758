import type {
  InsightsQuestionFragment,
  QuestionFragment,
} from "generated/graphql";

/**
 * This value is chosen kind of random <br>
 * Consider that this threshold should make sure that the "other answers" part should not be larger than other answer options
 */
export const MAX_DISPLAYABLE_CATEGORY_THRESHOLD = 30;

export const MAX_DISPLAYED_OPTIONS = 10;
/** Entries with a lower percentage cannot be displayed in a useful way - smaller values can not be hovered in donut chart */
export const MIN_PERCENTAGE_TO_BE_DISPLAYED = 0.015;

export interface AnswerInsightsProps {
  questions: InsightsQuestionFragment[];
}

export type AnswerDistribution = Map<string, number>;

export interface QuestionInsights {
  answerDistribution: AnswerDistribution;
  answeredCount: number;
}

export type AnswerCategories = { value?: string | null }[];
type AnswerDistributionValues = [string, number][];

export function filterQuestions(
  questions: InsightsQuestionFragment[],
): InsightsQuestionFragment[] {
  return questions.filter((question) => {
    return (
      !isObligatoryQuestion(question) &&
      isDisplayableAnswerDistribution(question) &&
      !isMediaQuestion(question) &&
      !isMetadata(question) &&
      !isAnalytics(question)
    );
  });
}

function isAnalytics(question: QuestionFragment) {
  const utmRegex = /utm/i;
  const specialAnalyticsKeys = ["wbraid", "gclid", "sfnsn"];

  return (
    utmRegex.test(question.id) || specialAnalyticsKeys.includes(question.id)
  );
}

function isMetadata(question: QuestionFragment) {
  return question.id === "external_browser_redirect";
}

function isMediaQuestion(question: QuestionFragment): boolean {
  const isUpload = isUploadQuestion(question);
  const isUtmContent = question.title === "UTM Content";
  return isUpload || isUtmContent;
}

function isUploadQuestion(question: QuestionFragment) {
  const identifiedAsUploadByTitle = question.title
    .toLowerCase()
    .includes("hochladen");

  return isUploadQuestionBasedOnAnswers(question) || identifiedAsUploadByTitle;
}

function isUploadQuestionBasedOnAnswers(question: QuestionFragment) {
  const MAX_ANSWERS_TO_BE_CHECKED = 5;
  const numberOfAnswers = Math.min(
    question.answers.length,
    MAX_ANSWERS_TO_BE_CHECKED,
  );

  for (let index = 0; index < numberOfAnswers; index++) {
    const isUploadedFile =
      question.answers[index]?.value?.startsWith("https://");
    if (isUploadedFile) {
      return true;
    }
  }

  return false;
}

export const getQuestionInsights = (
  question: QuestionFragment,
): QuestionInsights => {
  const answerDistribution: AnswerDistribution = new Map<string, number>();
  let answeredCount = 0;

  question.answers.forEach((answer) => {
    const options = answer.value?.split(";");
    if (options == null) return;
    for (const option of options) {
      const trimmedOption = option.trim();
      const updatedOptionCount =
        (answerDistribution.get(trimmedOption) ?? 0) + 1;
      answerDistribution.set(trimmedOption, updatedOptionCount);
    }
    answeredCount++;
  });

  return {
    answerDistribution,
    answeredCount,
  };
};

export function isDisplayableAnswerDistribution(
  question: QuestionFragment,
): boolean {
  return (
    question.id === "zip" ||
    question.answer_categories.length < MAX_DISPLAYABLE_CATEGORY_THRESHOLD
  );
}

/** Obligatory questions are questions that are the same for each campaign with limited informative value */
function isObligatoryQuestion(question: QuestionFragment): boolean {
  const obligatoryQuestionTitles = [
    "Cookies accepted",
    "Funnel Result",
    "Deine Postleitzahl",
    "Ich akzeptiere die Datenschutzbestimmungen und bin mit der Übermittlung und Verarbeitung meiner Daten einverstanden.",
    "Ich akzeptiere die Datenschutzbestimmungen von everbay und die Datenschutzbestimmungen der HUK-COBURG und bin mit der Übermittlung und Verarbeitung meiner Daten einverstanden.",
    "Ich willige in die Datenschutzbestimmungen sowie Übermittlung und Verarbeitung meiner Daten für die Bewerbung durch ein automatisiertes Profiling ein.",
  ];

  for (const obligatoryQuestionTitle of obligatoryQuestionTitles) {
    if (question.title === obligatoryQuestionTitle) {
      return true;
    }
  }

  return false;
}

export const getUnqualifiedLeads = (
  totalLeads?: number,
  qualifiedLeads?: number,
) => {
  if (totalLeads === undefined || qualifiedLeads === undefined) {
    return undefined;
  }
  return totalLeads - qualifiedLeads;
};

const COUNT_INDEX = 1;

function calculateReducedAnswerCount(
  reducedAnswerOptionsInCategoryOrder: [string, number][],
) {
  let reducedAnswerCount = 0;
  reducedAnswerOptionsInCategoryOrder.forEach(([_name, count]) => {
    reducedAnswerCount += count;
  });
  return reducedAnswerCount;
}

export const reduceExplicitlyDisplayedAnswerOptions = (
  questionInsights: QuestionInsights,
  answerCategories: AnswerCategories,
  maxDisplayedOptions = MAX_DISPLAYED_OPTIONS,
): QuestionInsights => {
  const answerDistributionArray = Array.from(
    questionInsights.answerDistribution.entries(),
  );
  const sortedAnswerDistributionArray = sortDescendingByAnswerCount(
    answerDistributionArray,
  );

  const reducedAnswerOptions: AnswerDistributionValues =
    reduceDisplayedAnswerOptionsOfSortedValues(
      sortedAnswerDistributionArray,
      questionInsights.answeredCount,
      maxDisplayedOptions,
    );

  const reducedAnswerOptionsInCategoryOrder: AnswerDistributionValues =
    sortDescendingByCategory(
      reducedAnswerOptions,
      answerCategories
        .map((category) => category.value?.trim())
        .filter((s): s is string => s != undefined),
    );

  const reducedAnswerCount = calculateReducedAnswerCount(
    reducedAnswerOptionsInCategoryOrder,
  );

  return {
    answerDistribution: new Map(reducedAnswerOptionsInCategoryOrder),
    answeredCount: reducedAnswerCount,
  };
};

function sortDescendingByAnswerCount(
  answerDistribution: AnswerDistributionValues,
): [string, number][] {
  return answerDistribution.sort(
    ([_nameA, countA], [_nameB, countB]) =>
      // sort descending
      countB - countA,
  );
}

function sortDescendingByCategory(
  answerDistributionValues: AnswerDistributionValues,
  answerCategories: string[],
): AnswerDistributionValues {
  function getIndex(name: string) {
    return name === "Sonstige Antworten"
      ? answerCategories.length
      : answerCategories.findIndex((c) => c === name);
  }

  return answerDistributionValues.sort(([nameA, _countA], [nameB, _countB]) => {
    const indexA = getIndex(nameA);
    const indexB = getIndex(nameB);

    return indexA - indexB;
  });
}

/**
 * Expects {@link sortedAnswerDistributionValues} to be sorted descending by AnswerCount (-> options with most answers come first)
 */
function reduceDisplayedAnswerOptionsOfSortedValues(
  sortedAnswerDistributionValues: AnswerDistributionValues,
  answeredCount: number,
  maxDisplayedOptions = MAX_DISPLAYED_OPTIONS,
): AnswerDistributionValues {
  const MAX_DISPLAYED_OPTION_INDEX = maxDisplayedOptions - 1;
  let maxDisplayIndexDueToPercentage = sortedAnswerDistributionValues.length;
  const isOthersCategoryNeeded =
    sortedAnswerDistributionValues.length > maxDisplayedOptions;
  const reducedAnswerOptions: AnswerDistributionValues = [];
  for (let i = 0; i < sortedAnswerDistributionValues.length; i++) {
    const count = sortedAnswerDistributionValues[i][COUNT_INDEX];
    const percentage = count / answeredCount;

    const notDisplayableInDonutChart =
      percentage < MIN_PERCENTAGE_TO_BE_DISPLAYED;
    if (notDisplayableInDonutChart) {
      maxDisplayIndexDueToPercentage = i;
    }

    const isNotExplicitlyDisplayed =
      i > MAX_DISPLAYED_OPTION_INDEX || i > maxDisplayIndexDueToPercentage;

    const isNotExplicitlyDisplayedButOthersNotCreatedYet =
      (i === MAX_DISPLAYED_OPTION_INDEX && isOthersCategoryNeeded) ||
      i === maxDisplayIndexDueToPercentage;

    if (isNotExplicitlyDisplayed) {
      reducedAnswerOptions[MAX_DISPLAYED_OPTION_INDEX][COUNT_INDEX] += count;
    } else if (isNotExplicitlyDisplayedButOthersNotCreatedYet) {
      reducedAnswerOptions.push(["Sonstige Antworten", count]);
    } else {
      reducedAnswerOptions.push(sortedAnswerDistributionValues[i]);
    }
  }

  return reducedAnswerOptions;
}
