import { Grid } from "@mui/material";
import { CampaignFilterTopBar } from "components/campaigns/filter/CampaignFilterTopBar";
import { useSearch } from "components/campaigns/filter/SearchContext";
import { HukGsCard } from "components/campaigns/HukGsCard";
import { useGetHukGsQuery } from "generated/graphql";
import { useUser } from "hooks/useUser";
import { useRef } from "react";
import ErrorDisplay from "../components/ErrorDisplay";
import Loading from "../components/Loading";
import Page from "../components/Page";

export default function HukCampaignsPage() {
  const anchorRef = useRef<HTMLDivElement>(null);
  const { hasAdvancedAccessToCampaigns, canSeeInternalInsights } = useUser();
  const { search } = useSearch();
  const { data, loading, error } = useGetHukGsQuery({
    variables: {
      hasAdvancedAccessToCampaigns: hasAdvancedAccessToCampaigns,
      canSeeInternalInsights: canSeeInternalInsights,
      hasAdvancedAccessToCampaignsOrCanSeeInternalInsights:
        hasAdvancedAccessToCampaigns || canSeeInternalInsights,
      query: {},
      limit: 1000,
      offset: 0,
    },
  });
  let gs_data = data?.campaign ?? [];
  search !== null &&
    (gs_data = gs_data?.filter((gs) =>
      gs.name?.toLowerCase().includes(search.toLowerCase()),
    ));
  return (
    <Page scrollable={(gs_data?.length ?? 0) > 0} title="Geschäftsstellen">
      <div ref={anchorRef} />
      <Grid
        container
        sx={{
          py: 2,
        }}
      >
        <CampaignFilterTopBar showFilter={false} />
      </Grid>
      {error ? (
        <ErrorDisplay message="Geschäftsstellen konnten nicht geladen werden." />
      ) : loading ? (
        <Loading />
      ) : (
        <Grid alignItems="stretch" container justifyItems="stretch" spacing={3}>
          {gs_data?.map((gs) => {
            return (
              gs?.gs_campaigns !== undefined && (
                <Grid display="flex" item key={gs.id} lg={6} sm={12} xl={4}>
                  <HukGsCard gs={gs} />
                </Grid>
              )
            );
          })}
        </Grid>
      )}
    </Page>
  );
}
